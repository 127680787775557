<template>
  <div class="container-fluid">
    <div class="row">
      <div class="title">{{$t("manage_users")}}</div>
      <div class="col-md-12 col-lg-12 mt-5">
        <label>
          <input class="form-control input-search-users"
                 type="text" placeholder="Type in"
                 @input="this.updateSearchText">
        </label>
        <button class="btn-submit ms-2" @click="this.btnSearch">{{$t("search")}}</button>
      </div>

      <div class="col-md-12 mt-2">
        <div class="table-responsive">
          <table class="table table-bordered border-light align-middle table-nowrap mb-0">
            <thead class="table-header">
            <tr>
              <th scope="col" v-on:click="sortTable(item)" class="cursor-pointer" v-for="(item, index) in this.columns" :key="index">
                {{$t(item)}}
                <i v-if="item === this.formdata.sortColumn && this.formdata.ascending"  class="las la-sort-down"></i>
                <i v-if="item === this.formdata.sortColumn && !this.formdata.ascending" class="las la-sort-up"></i>
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(row, index) of this.users" :key="index">
              <td>
                {{row.name}}
              </td>

              <td>
                <RouterLink :to="{name: 'userdetail', query: {id:row.id}}">{{row.email}}</RouterLink>
              </td>

              <td>{{row.card_brand}}</td>
              <td>{{row.card_exp_date}}</td>
              <td>{{ row.card_last4 }}</td>
              <td>
                <span v-show="row.paid_status==='paid'" class="badge rounded-pill bg-primary">Paid</span>
                <span v-show="row.paid_status==='canceled'" class="badge rounded-pill bg-danger">Cancelled</span>
              </td>
            </tr>
            </tbody>
          </table>

          <div class="d-flex justify-content-center mt-3" v-if="this.pages.length > 1">
            <div class="pagination-wrap hstack gap-2">
              <a class="page-item disabled" href="javascript:void(0)" v-if="page !== 1" @click="btnPrev">
                <i class="las la-angle-left"></i>
              </a>
              <ul class="pagination listjs-pagination mb-0">
                <li :class="{ active: pageNumber === page, disabled: pageNumber === '...' }"
                    v-for="(pageNumber, index) in pages.slice(page>= 5 ? page - 5:  0, page + 4)" :key="index" @click="this.btnPaginate(pageNumber)">
                  <a class="page" href="javascript:void(0)">{{ pageNumber }}</a>
                </li>
              </ul>
              <a class="page-item" href="javascript:void(0)" @click="btnNext" v-if="page < pages.length">
                <i class="las la-angle-right"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import {mapGetters, mapActions} from "vuex";

export default {
  name: "users",
  components: {

  },
  computed: {
    ...mapGetters("adminUsers", ["formdata", "columns", "users", "pages", "page"])
  },
  methods: {
    ...mapActions("adminUsers", ["sortTable", "getUsers", "btnActionPrev", "btnActionNext", "setSearchText"]),

    updateSearchText(e){
      this.setSearchText(e.target.value);
    },
    btnNext() {
      this.$isLoading(true);
      this.btnActionNext()
          .catch(() => {
            this.$router.push({
              name: 'home'
            })
          })
          .finally(() => {
            this.$isLoading(false);
          });
      },

    btnPrev() {
      this.$isLoading(true);
      this.btnActionPrev()
          .catch(() => {
            this.$router.push({
              name: 'home'
            })
          }).finally(() => {this.$isLoading(false)});
    },

    btnPaginate(pageNum){
      this.$isLoading(true);
      this.getUsers(pageNum)
          .catch(() => {
            this.$router.push({
              name: 'home'
            })
          }).finally(() => {this.$isLoading(false)});
    },

    btnSearch(){
      this.$isLoading(true);
      this.getUsers(1)
          .catch(() => {
            this.$router.push({
              name: 'home'
            })
          }).finally(() => {this.$isLoading(false)});
    },
  },

  beforeMount() {
    this.$isLoading(true);
    this.getUsers(this.page)
        .catch(() => {
          this.$router.push({
            name: 'home'
          })
        })
        .finally(() => {
          this.$isLoading(false);
        });
  }
}
</script>


<style scoped lang="scss">
tbody{
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  tr:nth-child(odd) {
    background: rgba(231, 234, 249, 0.4)!important;
    border-bottom: 1px solid #C6C6C6!important;
    border-radius: 7px!important;
  }
}

.input-search-users{
  padding: 5px 0 5px 12px;
  background: #FFFFFF;
  border: 1px solid #858585;
  border-radius: 10px;
  font-weight: 400;
  font-size: 14px;
  color: #858585;
}

button.btn-submit{
  background: #F10023;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
  border-radius: 9px;
  justify-content: center;
  align-items: center;
  padding: 3px 8px;
  gap: 10px;
  border: 0;
  color: white;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

button:hover{
  color: white;
  background: #e70627;
}
</style>